<template>
  <div class="header-interaction-container">
    <div class="hi-toolbar">
      <slot v-if="$slots['navigation-toolbar']" name="navigation-toolbar" />
      <slot v-if="$slots.title" name="title" />
    </div>
    <div v-if="$slots['cta-button']" class="hi-ctaButton">
      <slot name="cta-button" />
    </div>

    <div class="hi-items">
      <slot name="items" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-interaction-container {
  margin-top: $uds-spacing-3;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    "navbar navbar navbar"
    "cta_btn cta_btn cta_btn"
    "items items items";

  .hi-toolbar {
    grid-area: navbar;
  }

  .hi-ctaButton {
    grid-area: cta_btn;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin: $uds-spacing-1 0;
    margin-top: $uds-spacing-4;
  }

  .hi-items {
    grid-area: items;
    display: flex;
    align-items: center;
  }
}

.admin_management_container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background: $uds-white;
  padding: 0 $uds-spacing-2;
}
</style>
